import "./styles.scss"

import React from "react"
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api"
import { withPrefix } from "gatsby"

const containerStyle = {
  width: "100%",
  height: "720px",
}

const center = {
  lat: 50.0773837,
  lng: 19.9352284,
}

const mapStyle = [
  {
    featureType: "administrative",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#444444",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        color: "#f2f2f2",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "all",
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 45,
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "all",
    stylers: [
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [
      {
        color: "#C9895B",
      },
      {
        visibility: "on",
      },
    ],
  },
]

const Map = () => {
  return (
    <div className="google-map">
      <LoadScript googleMapsApiKey="AIzaSyCWnBchH4qujhQVPUVdX6DRNzxo2R8j0PY">
        <GoogleMap
          center={center}
          zoom={14}
          options={{ styles: mapStyle }}
          mapContainerStyle={containerStyle}
        >
          <Marker
            icon={withPrefix("/ico-pointer.png")}
            position={{
              lat: 50.0773837,
              lng: 19.9202284,
            }}
          />
        </GoogleMap>
      </LoadScript>
    </div>
  )
}

export default Map
